body {
    margin: 0;
    padding: 0;
}

#map {
    position: absolute;
    margin: 0px auto;
    width: 60%;
    height: 60%;
    border-radius: 10px;
}

#heatmap {
    position: absolute;
    margin: 0px auto;
    width: 60%;
    height: 60%;
    border-radius: 10px;
}

#tx100map {
    position: absolute;
    width: 60%;
    height: 60%;
    border-radius: 10px;
}

/* .caseStudyMapCard {
    overflow: hidden;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
} */

.divider {
    border-bottom: solid;
    border-width: 10px;
}

.homegrid3 ul {
    padding: 20px;
}

ul {
    grid-template-columns: repeat(1, 1fr) !important;
}

.homesubheading,
.homeheading,
.homedescription,
.homesubdescription {
    font-family: Geneva, Verdana, sans-serif;
}

.homeheading {
    font-weight: bolder;
    font-size: 3rem;
    text-align: center !important;
    padding-top: 20px;
}

.homesubheading {
    text-align: center !important;
}

.homedescription {
    margin: 50px 0 0 0;
    width: -moz-fit-content;
    width: fit-content;
    word-wrap: break-word;
    letter-spacing: 0.3px;
    font-size: 20px;
    line-height: 38px;
    font-weight: 300;
    padding: 0 0 15px 0;
    text-align: justify !important;
}

.homedescription span {
    font-size: 40px !important;
    color: #2DB3D1 !important;
}

.homesubdescription {
    font-size: 14px;
}

.homesubdescription span {
    font-size: 16px;
    font-weight: 900;
}

.vizbutton {
    border-radius: 50px !important;
    font-family: Geneva, Verdana, sans-serif !important;
    font-weight: 800 !important;
    text-transform: capitalize !important;
    font-size: 20px !important;
    background-color: #2DB3D1 !important;
}

.dropdown-menu {
    line-height: 2 !important;
}

.MuiImageListItemBar-title {
    font-family: Geneva, Verdana, sans-serif !important;
    font-size: 15px !important;
}

.homegrid1 {
    color: white !important;
    background-color: #2DB3D1 !important;
}

.homegrid1 .MuiPaper-root {
    color: white;
    background-color: transparent !important;
    margin-top: 0px;
}

.homegrid3 {
    background-color: #efe9e4 !important;
}

.homegrid3 .MuiPaper-root {
    background-color: #efe9e4 !important;
}

.homelayout hr {
    height: 3px !important;
    color: #2DB3D1 !important;
    border-bottom: #040404 solid !important;
    opacity: 1 !important;
}
.homelayout {
   margin-top: 90px;
}

.footer {
    background-color: #efe9e4 !important;
}

.scrollTop {
    position: fixed;
    width: 100%;
    bottom: 10px;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 1;
    float: right;
    height: 20;
    display: flex;
    right: 1%;
    font-size: 35px !important;
    background-color: #2DB3D1;
    border-radius: 20px;
}

.imagelist .MuiImageListItem {
    width: 250px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.5;
    }
}

.grid1 {
    margin-left: 60px !important;
    margin-right: 60px !important;
    padding-bottom: 30px !important;
}

.grid2,
.grid3,
.grid4,
.grid5 {
    margin-left: 60px !important;
    padding-top: 30px !important;
    margin-right: 60px !important;
    padding-bottom: 30px !important;
}

@media screen and (min-width: 1900px) and (min-width: 1300px) and (min-device-width : 768px) and (max-device-width : 1024px) {

    .MuiBox-root-2,
    .MuiBox-root-4,
    .MuiBox-root-6,
    .MuiBox-root-8,
    .MuiBox-root-10,
    .grid1,
    .grid2,
    .grid3 {
        width: auto;
        height: auto;
        display: flex;
        margin-left: 60px !important;
        padding-top: 50px !important;
        margin-right: 60px !important;
        align-content: center;
        padding-bottom: 200px !important;
    }
}

@media screen and (orientation : landscape) and (-webkit-min-device-pixel-ratio: 1) and (min-device-width : 820px) and (max-device-width : 1007px) {

    .MuiBox-root-2,
    .MuiBox-root-4,
    .MuiBox-root-6,
    .MuiBox-root-8,
    .MuiBox-root-10,
    .grid1,
    .grid2,
    .grid3 {
        width: auto;
        height: auto;
        display: flex;
        margin-left: 30px !important;
        margin-right: 30px !important;
        align-content: center;
        padding-bottom: 50px !important;
    }
}

/* section1 */
@media screen and (min-width: 1300px) {
    #figure1 {
        padding-top: 430px;
        text-align: center;
    }

    .stepDescription {
        margin: 1rem;
        font-size: 17px;
    }

    .homedescription {
        margin: 10px 0 0 0;
    }
}

@media screen and (min-width: 1600px) {
    #figure1 {
        text-align: center;
    }
}

@media screen and (min-width: 1900px) {
    #figure1 {
        padding-top: 580px;
        text-align: center;
    }

    .homedescription {
        margin: 50px 0 0 0;
    }
}

@media only screen and (max-width: 1024px) {}

/* section2 */
@media screen and (min-width: 1300px) {

    .figure1,
    .figure2,
    .figure3 {
        padding-top: 50%;
        text-align: center;
    }

    .stepDescription {
        margin: 1rem;
        font-size: 17px;
    }
}

@media screen and (min-width: 1600px) {

    .figure2,
    .figure1,
    .figure3 {
        text-align: center;
    }
}

@media screen and (min-width: 1900px) {

    .figure2,
    .figure1,
    .figure3 {
        padding-top: 50%;
        text-align: center;
    }
}

@media only screen and (max-width: 1900px) {
    .border-supply-chain
    {
        height: 525px!important;
    }


}


@media screen and (min-width: 1900px) {

    .border-supply-chain
    {
        height: 800px!important;
    }

}

.caseStudies{
    padding-top: 10px !important;
}

.commodity-corridors{
    padding-top: 2.2vh !important;
}

.supply-chain-fluidity{
    padding-top: 15px !important;
}

#roadClosure tbody tr{
  text-align: left!important;
}

#roadClosure thead tr th{
    white-space: nowrap;
}
#roadClosure th{
    padding: 6px 6px 6px 6px !important;
    text-align: left!important;
}
#roadClosure td{
  padding: 6px 6px 6px 6px !important;
}

#roadClosure caption{
    border: none;
}