.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.chartcontainer.chartrow {
  background: none;
}

.navbar-fixed-top {
  background: #000000 !important;
  border-bottom-style: solid !important;
  border-bottom-color: #2DB3D1 !important;
  border-bottom-width: 5px !important;
}

.navbar-brand {
  margin-top: 8px;
  font-size: 30px !important;
  color: white;
}

.docs-sidenav {
  margin-top: 20px;
  margin-bottom: 20px;
}

.docs-sidebar .nav>li>a {
  color: #CCC;
  background: #FFF;
  display: block;
  padding: 4px 20px;
  font-size: 15px;
  font-weight: 500;
}

.docs-sidebar .nav>li>a.active {
  color: #000;
  font-weight: 600;
  background: #FFF;
  border-bottom-style: solid;
  border-bottom-color: #2DB3D1;
}

/*
* Global add-ons
*/

.sub-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

/*
* Top navigation
* Hide default border to remove 1px line.
*/
.navbar-fixed-top {
  border: 0;
}

/*
* Sidebar
*/

/* Hide for mobile, show later */
.sidebar {
  display: none;
}

@media (min-width: 768px) {
  .sidebar {
    position: fixed;
    top: 51px;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    /* Scrollable contents if viewport is shorter than content. */
    background-color: #f5f5f5;
    border-right: 1px solid #eee;
  }
}

/* Sidebar navigation */
.nav-sidebar {
  margin-right: -21px;
  /* 20px padding + 1px border */
  margin-bottom: 20px;
  margin-left: -20px;
}

.nav-sidebar>li>a {
  padding-right: 20px;
  padding-left: 27px;
}

.nav-sidebar>li>a.active {
  padding-left: 20px;
  border-left: #2DB3D1;
  border-left-style: solid;
  border-left-width: 7px;
  background: #ECECEC;
}

.nav-sidebar>.active>a,
.nav-sidebar>.active>a:hover,
.nav-sidebar>.active>a:focus {
  color: #fff;
  background-color: #428bca;
}

.sidebar-heading {
  padding-left: 0px;
  text-transform: uppercase;
  font-weight: 800;
}

/*
* Main content
*/

.main {
  padding: 20px;
}

@media (min-width: 768px) {
  .main {
    padding-right: 40px;
    padding-left: 40px;
  }
}

.main .page-header {
  margin-top: 0;
}

.nav-dropdown .btn {
  margin-right: 10px;
  text-transform: capitalize;
  color: white !important;
  font-weight: bold !important;
  background-color:#2DB3D1 !important;
  border: none !important;
}

.nav-dropdown .btn:hover {
  color: black !important;
  background-color:#2DB3D1 !important;
}

.menu-nav-link {
  margin-right: 10px;
  font-weight: bold !important;
  background-color: #2DB3D1 !important;
  border: none !important;
  text-decoration: none;
  color: white!important;
}

.menu-nav-link:hover {
  text-decoration: none;
  color: black!important;
}

#menuappbar {
  font-family: Geneva, Verdana, sans-serif !important;
  background: #000000 !important;
  color: white !important; 
  /* border-bottom-style: solid !important;
  border-bottom-color: #2DB3D1 !important; */
  /* border-bottom-width: 5px !important;
 */
  /* border-bottom-width: 2.5px !important;
    color: white !important; */
    /* height: 75px; */
}

.sticky{
  position: sticky;
}

.subToolbar{
  background-color: #2DB3D1 !important;
  min-height: 2vh !important;
}

.mainToolbar{
  min-height: 3vh !important;
}

.appNavButtonGroup{
  align-items: center;
}

.css-1f8a19r-MuiTypography-root {
  font-family: Geneva, Verdana, sans-serif !important;
}

.map-control-panel {
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  margin: 10px 10px;
  border: 2px solid rgb(198, 197, 197);
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(162, 160, 160, 0.3);
}

.map-control-panel .icon {
  color: rgb(30, 29, 29);
  text-decoration: none;
}

.map-control {
  font-family: Geneva, Verdana, sans-serif !important;
  font-size: 15px !important;
}

#commodity-legend {
  position: absolute;
  bottom: 40px;
  left: 10px;
  z-index: 1;
  background-color: #f3f0f0;
  padding: 5px
}

.bar-color {
  display: inline-block;
  border-radius: 20%;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.supply-chain-fluidity-tabs {
  font-weight: 700 !important;
  font-size: 15px !important;
}
 .scf{
    display: block !important;
    max-width:950px !important;
    max-height:450px !important;
    width: auto !important;
    height: auto !important;
 }
.scfi-table-header {
  background: #78b4e5;
  /* color: #ffffff; */
  height: 100%;
}

.highcharts-tooltip table th {
  padding-bottom: 0.2em;
  border-bottom: 1px solid silver;
}

.donut-chart-tooltip-dot {
  height: 10px;
  width: 10px;
  /* background-color: #bbb; */
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

table tr th {
  padding: 0.1px !important;
}

table tr {
  cursor: pointer !important;
}

table tr td {
  padding: 0 !important;
}

#basemaps-radio-buttons-group-label{
  font-family: Geneva, Verdana, sans-serif !important;
  font-size: 16px ;
}

.basemap-layers{
  font-family: Geneva, Verdana, sans-serif !important;
  font-size: 14px ;
}

.map-layers{
  font-family: Geneva, Verdana, sans-serif !important;
  font-size: 14px ;
}

.airportDelay{
  z-index: 1;
}
.airportDelayLines{
  z-index:2;
}
