body {
 
   margin: 0; 
   padding: 0; 
  }
  

/* .map-container { position: absolute; top: 0; bottom: 0; width:50%; height: 100%; } */
/* .map-container { position: absolute;} */

/* #map { position: absolute; top: 20px; bottom: 0; width:100%; height: 90%; } */
#map {  /* display: block; */
  position: absolute;
  margin: 0px auto;
  width: 50%;
  height: 43%;
  /* padding: 4px; */
  /* border: none; */
  border-radius: 10px;
  /* font-size: 12px; */
  /* top: 7%; */
  /* left: 0; */
}

#hmap {
  
      /* display: block; */
      position: absolute;
      margin: 0px auto;
      width: 50%;
      height: 40%;
      /* padding: 4px; */
      /* border: none; */
      border-radius: 10px;
      /* font-size: 12px; */
      bottom: 4%;
      
}

#hdesc
{
  margin-top: 30%;
  position: absolute;

}

#cdesc
{
  margin-top: 24%;
  position: absolute;
}

/* table{
  width:115% !important;
} */

#travelTraces {
  /* display: block; */
  position: absolute;
  margin: 0px auto;
  width: 100%;
  height:100%;
  /* padding: 4px; */
  /* border: none; */
  border-radius: 10px;
  /* font-size: 12px; */
  /* right: 0;
  float:right;  */
   left: 0;
   float: left;

}

.view {
  width: 90vw;
  max-width: 1170px;
  margin: 5rem auto;
  display: grid;
  gap: 2rem;
}

tr {
  padding: 0;
  text-align: center;
}

p {
  margin: 1rem;
  width: fit-content;
  right: 0;
 text-align: justify;
  line-height: 1.6;
  word-wrap: break-word;
  letter-spacing: .2px;
  font-size: 1.6rem;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.heading{
  margin: 1rem;
  font-size: 1.5rem;
}

.stepDescription
{
  margin: 1rem;
  font-size: 1.3rem;
}

.stepDescription span
{
  margin: 1rem;
  font-size: 1.2rem;
}

.heatmap {
  height: 50vh !important;
}

.heading1{
  margin-top: 8%;
}

@media screen and (min-width: 1400px) {
  #cdesc
  {
    margin-top: 24%;
    margin-left: 10%;
  }
  .section1
  {
    top:1% !important
  }
}
@media screen and (min-width: 1600px) {
  
}
@media screen and (min-width: 1900px) {
  
  #cdesc
  {
    margin-top: 23%;
    margin-left: 16%;
  }
}


@media only screen and (max-width: 1024px) {
 
}


@media screen and (min-width: 1400px) {
  .section3
  {
  margin-top: 3%!important;

  }
}
@media screen and (min-width: 1600px) {
  .section3
  {
  margin-top: 23%!important;
  }
}
@media screen and (min-width: 1900px) {
  .section3
  {
  margin-top:21%!important;
  }
  #hdesc
  {
    margin-top: 21%!important;
  }
}


@media only screen and (max-width: 1024px) {
 .section3
 {
 margin-top: 23%!important;
 }
}



@media screen and (min-width: 1400px) {
  .section2
  {
    bottom:4% !important;
    margin-top: 7%!important;
  }
  #hdesc
  {
    margin-top:  24%!important;
    margin-left: 10%;
  }
}
@media screen and (min-width: 1600px) {
  .section2
  {
    bottom:4% !important;
  }
}
@media screen and (min-width: 1900px) {
  .section2
  {
    margin-top: 16%!important;
  }
  #hdesc
  {
    margin-left: 15%;
  }
}


@media only screen and (max-width: 1024px) {
 .section2
 {
bottom:4% !important;
 }
}

@media screen and (min-width: 1400px) {
  .section4
  {
  margin-top: 3%!important;
  }
}

@media screen and (min-width: 1900px) {
  .section4
  {
  margin-top: 3%!important;
  }
  
}

@media screen and (min-width: 1400px) {
  .section5
  {
  margin-top: 3%!important;
  }
}

@media screen and (min-width: 1900px) {
  .section5
  {
  margin-top: 3%!important;
  }
  
}

.sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    margin: 12px;
    border-radius: 4px;
  }

.legend {
  background-color: #fff;
  border-radius: 3px;
  /* bottom: 30px; */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  max-width: 125px;
}

.legend h4 {
  margin: 0 0 10px;
}

.legend h6 {
  font-size: 13px;
}

.legend div span {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  width: 10px;
  opacity: 0.5;
}

.legend-heatmap {
  max-width: 200px;
}

.rank1 {
  /* background-color: #0080ff; */
  background-color: red;
}
.rank50 {
  background-color: #e6e620;
}
.rank100 {
  background-color: green;
}

.l60{
/* background-color: #0080ff; */
  background-color: red;}

.l45{
  background-color: #6676ed;
}

.l30{
  background-color: #e6e620;
}

.l15{
  background-color: #f0a3ed;
}

.l10{
  background-color: #04c96a;
  
}

.neutral
{
  background-color:#75e8fa;
  opacity: 1;
}

.low
{
  background-color:#f7c352;
  opacity: 1;
}

.high
{
  background-color:#f55656;
  opacity: 1;
}

.freight {
  width: 50vw;
  max-width: 100%;
  margin: 5rem auto;
  display: grid;
  gap: 2rem;
}

@media only screen and (max-width: 1024px) {
 
}

@media screen and (min-width: 768px) {
  .freight {
    grid-template-columns: repeat(2, 1fr);
    /* align-items: start; */
  }
}

#commcorridormap {

  /* display: block; */
  position: absolute;
  margin: 0px auto;
  /* margin-top: 400px; */
  width: 65%;
  height: 70%;
  /* padding: 4px; */
  /* border: none; */
  border-radius: 10px;
  /* font-size: 12px; */
  /* top: 55px; */
  left: 0;

}